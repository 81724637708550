

const axios = require('axios').default;
console.log(process.env.NODE_ENV)
console.log(process.env)
const host = (location.host).split(":");
let baseUrl;
let baseUrlMain;
let service_api;
let position_amount;
let machine_number;
let production  = true;

baseUrl = production == false ? "http://"+host[0]+":8000/api" : "https://internship-eduapp.rmu.ac.th/api";
baseUrlMain = production == false ? "http://"+host[0]+":8000" : "https://internship-eduapp.rmu.ac.th/" ;

console.log(machine_number)

const instance = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {
        'X-Custom-Header': 'foobar',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    }
});





const API = {
    Auth: '/login',
    User: '/users',
    Department: '/department',
    Getdata: '/section',
    AAR1: '/update_aar_1',
    PLC: '/update_plc',
    Profile: '/update_profile',
    PLC2: '/update_plc2',
    AAR2: '/update_aar_2',
    AAR3: '/update_aar_3',
    Plan: '/update_plan',
    PlanHistory: '/update_plan_history',
    PlanDetail: '/update_plan_detail',
    PlanRequirement: '/update_plan_requirement',
    Certificate: '/update_certificate',
    Course: '/update_course',
    Works: '/update_works',
    WorksCommunity: '/update_works_community',
    Product: '/products',
    Stock: '/stocks',
    Order: '/order',
    Category: '/category',
    Machine: '/machine',
    Subcategory: '/subcategory',
    ListSubcategory: '/subcategory/list',
    DeletePlanDetail: '/delete_plan_detail',
    DeletePlanHistory: '/delete_plan_history',
    DeleteCer: '/delete_cer',
    DeleteCourse: '/delete_course',
    DeleteWork: '/delete_work',
    DeleteWorkCommu: '/delete_work_commu',
    DeleteLitLink: '/delete_list_link',
    Assessment: '/assessment',
    GetStudent: '/student_list',
    GetUsers: '/users',
    ListLink: '/list_link',
    UpdateProfile: '/upload_profile',
    UpdateUser: '/update_user',
}

const SERVICE = {
    CHECK : 'check',
    DEL : 'del_product',
    RESTOER : 'restore_product',
    UPDATE : 'update_product',
    UPDATECategory : 'update_category',
    UPDATEUser : 'update_users',
}



export { SERVICE,API,instance,baseUrl,baseUrlMain,position_amount,service_api };
