<template>
  <div class="list_card"  >
    <v-row>
      <v-col class="thumb_list" xl="4" lg="4" md="6" cols="12" >
        <img class="thumb_profile" :src="baseUrl+'/'+JSON.parse(data.profile).path_thumb" />
        <img class="thumb_school" :src="baseUrl+'/'+JSON.parse(data.relationwork.school.logo).path_thumb" />
      </v-col>
      <v-col xl="8" lg="8" md="6" cols="12" >
        <font><b>ชื่อ-นามสกุล:</b>{{ data.first_name }} {{ data.last_name }}</font><br>
        <font><b>รหัสนักศึกษา:</b>{{ data.student_code }}</font><br>
        <font><b>สาขา:</b>{{ data.branch }}</font><br>
        <font><b>โรงเรียน:</b>{{ data.relationwork.school.name }}</font><br>
      </v-col>
    </v-row><br>
    <div class="list_btn" >
      <button class="btn_" @click="callback_asm" > <i class="fa fa-circle-info"></i> ดูรายละเอียด</button>
      <button class="btn_" @click="callback" > <i class="fa fa-file-circle-check"></i> ประเมิน</button>
    </div>

    <hr class="line" ><br>
    <v-row>
      <v-col>
        <div align="center" >
        <font><b>ภาคเรียนที่ 1</b></font>
        </div>
        <div class="list_box">
          <div class="box-data" >{{ rate_1 }}</div>
          <div class="box-data" >{{ rate_2 }}</div>
          <div class="box-data" >{{ rate_3 }}</div>
        </div>
      </v-col>
      <v-col>
        <div align="center" >
        <font><b>ภาคเรียนที่ 2</b></font>
        </div>
        <div class="list_box">
          <div class="box-data" >{{ rate_4 }}</div>
          <div class="box-data" >{{ rate_5 }}</div>
          <div class="box-data" >{{ rate_6 }}</div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import  {baseUrlMain } from "../const/api";
import {getData} from "../lib/controller";
import {ResponseData} from "../lib/utility";

export default {
  name: "StudentCard",
  props:["callback","data","types","callback_asm"],
  data:()=>(
      {
        baseUrl:baseUrlMain,
        users: JSON.parse(localStorage.getItem('user')),
        rate_1:"-",
        rate_2:"-",
        rate_3:"-",
        rate_4:"-",
        rate_5:"-",
        rate_6:"-",
        menu:false,
      }
  ),
  methods:{
    async GetData() {

      this.is_loading = true;
      console.log(this.types)
      console.log(this.users)

      let params = {
        "type":this.types,
        "student_id":this.data.id,
        "id":this.users.id,
      }

      await getData(params, (response) => {

        const {status, data} = ResponseData(response)

        let count = 0

        switch (status) {
          case 200:
            console.log(data.profile)
            this.rate_data = data.rate;

            this.assessment = data.assessment;


            this.rate_data.forEach((result) => {
              result.list.forEach((results) => {
                results.index = count;
                count++
              });

              this.is_show_1 = true;

              let rate_1 = this.assessment.find((result)=> result.rate_level == 1 )
              let rate_2 = this.assessment.find((result)=> result.rate_level == 2 )
              let rate_3 = this.assessment.find((result)=> result.rate_level == 3 )

              let rate_4 = this.assessment.find((result)=> result.rate_level == 4 )
              let rate_5 = this.assessment.find((result)=> result.rate_level == 5 )
              let rate_6 = this.assessment.find((result)=> result.rate_level == 6 )



              if(rate_1){
                let rate_1_total_j = (JSON.parse(rate_1.data));
                let rate_total_1 = 0;
                rate_1_total_j.forEach((result)=>{
                  rate_total_1 += result.val;
                });
                this.rate_1 = rate_total_1+"/"+(JSON.parse(rate_1.data).length*5)
              }

              if(rate_2){

                let rate_2_total_j = (JSON.parse(rate_2.data));
                let rate_total_2 = 0;
                rate_2_total_j.forEach((result)=>{
                  rate_total_2 += result.val;
                });

                this.rate_2 = rate_total_2+"/"+(JSON.parse(rate_2.data).length*5)
              }

              if(rate_3){

                let rate_3_total_j = (JSON.parse(rate_3.data));
                let rate_total_3 = 0;
                rate_3_total_j.forEach((result)=>{
                  rate_total_3 += result.val;
                });

                this.rate_3 = rate_total_3+"/"+(JSON.parse(rate_3.data).length*5)
              }

              if(rate_4){

                let rate_4_total_j = (JSON.parse(rate_4.data));
                let rate_total_4 = 0;
                rate_4_total_j.forEach((result)=>{
                  rate_total_4 += result.val;
                });

                this.rate_4 = rate_total_4+"/"+(JSON.parse(rate_4.data).length*5)
              }

              if(rate_5){

                let rate_5_total_j = (JSON.parse(rate_5.data));
                let rate_total_5 = 0;
                rate_5_total_j.forEach((result)=>{
                  rate_total_5 += result.val;
                });


                this.rate_5 = rate_total_5+"/"+(JSON.parse(rate_5.data).length*5)
              }


              if(rate_6){

                let rate_6_total_j = (JSON.parse(rate_6.data));
                let rate_total_6 = 0;
                rate_6_total_j.forEach((result)=>{
                  rate_total_6 += result.val;
                });

                this.rate_6 = rate_total_6+"/"+(JSON.parse(rate_6.data).length*5)
              }




            });

            this.count = count;


            this.$refs.topProgress.done()
            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
  },
  async mounted() {
    await this.GetData()
  }
}
</script>

<style scoped>
.thumb_profile{
  width: 100%;
  border-radius: 10px;
}
.list_card{
  padding: 1rem;
  border: 2px solid #ebebeb;
  color: #3a3a3a;
  border-radius: 10px;
  position: relative;
}
.btn_more{
  font-size: 0.9rem;
  padding: 0.5rem;
  background: #3780C8;
  color: white;
  border: 2px solid #5bb0f3;
  border-radius: 10px;
}
.line{
  border: 1px solid #00000014;
}
.list_box{
  display: flex;
  justify-content: space-around;
  gap: 5px;
}
.box-data{
  padding: 0.5rem;
  border: 1px solid #6fa2c0;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  background: #74c7f7;
  color: white;
}
.thumb_list{
  position: relative;
}
.thumb_school{
  width: 30%;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.list_btn{
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1rem;
}
.btn_{
  padding: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background: #74c7f7;
  display: flex;
  border-radius: 10px;
  gap: 5px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.43);
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.43);
  -moz-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.43);
  align-content: center;
  align-items: center;
  justify-content: center;
}
.btn_ i{
  font-size: 1.3rem;
}
.list_icon{
  position: absolute;
  top:0;
  right:10px;
  font-size: 1.5rem;
}
.menu_sub{
  color: #74c7f7;
}
.list_menu_sub{
  position: absolute;
  display: block;
  width: 10rem;
  right: 0;
  background: white;
}
.list_menu_sub li{
  list-style: none;
  font-size: 1.1rem;
  font-weight: 500;
}
</style>
