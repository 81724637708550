<template>
  <v-breadcrumbs :items="items">
    <div class="card_container">
      <div class="card_header">
        <div>{{ section_title }} (ภาคเรียนที่ {{ year }})</div>
      </div>

      <div class="form_card">
        <font>{{ form_title }}</font><br><br>
        <p v-html="statement"  ></p>
        <font>{{ title_sub }}</font>

        <v-row class="mt-2 mb-2" >
          <v-col cols="12"  >
            <v-row>
              <v-col xl="2" lg="2" md="6" xs="6" cols="2" v-if="is_rate_1" >
                <v-checkbox
                    v-model="is_show_1"
                    label="บันทึกครั้งที่ 1/1"
                    color="red"
                    :value=true
                    hide-details
                ></v-checkbox>
              </v-col>

              <v-col xl="2" lg="2" md="6" xs="6" cols="2" v-if="is_rate_2" >
                <v-checkbox
                    v-model="is_show_2"
                    label="บันทึกครั้งที่ 1/2"
                    color="red"
                    :value=true
                    hide-details
                ></v-checkbox>
              </v-col>

              <v-col xl="2" lg="2" md="6" xs="6" cols="2" v-if="is_rate_3" >
                <v-checkbox
                    v-model="is_show_3"
                    label="บันทึกครั้งที่ 1/3"
                    color="red"
                    :value=true
                    hide-details
                ></v-checkbox>
              </v-col>

              <v-col xl="2" lg="2" md="6" xs="6" cols="2" v-if="is_rate_4" >
                <v-checkbox
                    v-model="is_show_4"
                    label="บันทึกครั้งที่ 2/1"
                    color="red"
                    :value=true
                    hide-details
                ></v-checkbox>
              </v-col>

              <v-col xl="2" lg="2" md="6" xs="6" cols="2" v-if="is_rate_5" >
                <v-checkbox
                    v-model="is_show_5"
                    label="บันทึกครั้งที่ 2/2"
                    color="red"
                    :value=true
                    hide-details
                ></v-checkbox>
              </v-col>

              <v-col xl="2" lg="2" md="6" xs="6" cols="2" v-if="is_rate_6" >
                <v-checkbox
                    v-model="is_show_6"
                    label="บันทึกครั้งที่ 2/3"
                    color="red"
                    :value=true
                    hide-details
                ></v-checkbox>
              </v-col>

            </v-row>
          </v-col>
        </v-row>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <table border="1"  class="table_form">
            <tr>
              <th rowspan="3">สมรรถนะ</th>
              <th rowspan="3"  >พฤติกรรมบ่งชี้</th>
              <th colspan="5" v-if="is_show_1"  >ครั้งที่ 1 <br> สัปดาห์ที่ 3 - 5 <br></th>
              <th colspan="5" v-if="is_show_2" :class="{ is_not: !is_rate_2 }">ครั้งที่ 2 <br> สัปดาห์ที่ 7 - 9 <br></th>
              <th colspan="5" v-if="is_show_3" :class="{ is_not: !is_rate_3 }">ครั้งที่ 3 <br> สัปดาห์ที่ 11 - 14 <br></th>
              <th colspan="5" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" >ครั้งที่ 4 <br> สัปดาห์ที่ 3 - 5 <br></th>
              <th colspan="5" v-if="is_show_5" :class="{ is_not: !is_rate_5 }">ครั้งที่ 5 <br> สัปดาห์ที่ 7 - 9 <br></th>
              <th colspan="5" v-if="is_show_6" :class="{ is_not: !is_rate_6 }">ครั้งที่ 6 <br> สัปดาห์ที่ 11 - 14 <br></th>
            </tr>
            <tr>
              <td colspan="5" v-if="is_show_1"  align="center">ผลการประเมิน</td>
              <td colspan="5" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" align="center">ผลการประเมิน</td>
              <td colspan="5" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" align="center">ผลการประเมิน</td>
              <td colspan="5" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" align="center">ผลการประเมิน</td>
              <td colspan="5" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" align="center">ผลการประเมิน</td>
              <td colspan="5" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" align="center">ผลการประเมิน</td>
            </tr>
            <tr>
              <td align="center" v-if="is_show_1" class="data_list">1</td>
              <td align="center" v-if="is_show_1" class="data_list">2</td>
              <td align="center" v-if="is_show_1" class="data_list">3</td>
              <td align="center" v-if="is_show_1" class="data_list">4</td>
              <td align="center" v-if="is_show_1" class="data_list">5</td>

              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">1</td>
              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">2</td>
              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">3</td>
              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">4</td>
              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">5</td>

              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">1</td>
              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">2</td>
              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">3</td>
              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">4</td>
              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">5</td>

              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">1</td>
              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">2</td>
              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">3</td>
              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">4</td>
              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">5</td>

              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">1</td>
              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">2</td>
              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">3</td>
              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">4</td>
              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">5</td>


              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">1</td>
              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">2</td>
              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">3</td>
              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">4</td>
              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">5</td>

            </tr>

            <tbody v-for="(result) in rate_data" :key="result.id">
            <tr class="row_data" v-for="(results,indexs) in result.list" :key="results.id">
              <td v-if="indexs == 0" :rowspan="result.list.length">{{ result.title }}</td>
              <td class="pa-3" >{{ results.title }}</td>

              <td align="center" v-if="is_show_1" class="data_list">
                <CheckBoxs
                    :is_check="data_list_1[results.id] == 1"
                    :check="()=> Checks(1,results.id,1) "
                    class_name="red_color"
                />
              </td>
              <td align="center" v-if="is_show_1" class="data_list">

                <CheckBoxs
                    :is_check="data_list_1[results.id] == 2"
                    :check="()=> Checks(1,results.id,2) "
                    class_name="red_color"
                />

              </td>
              <td align="center" v-if="is_show_1" class="data_list">

                <CheckBoxs
                    :is_check="data_list_1[results.id] == 3"
                    :check="()=> Checks(1,results.id,3) "
                    class_name="red_color"
                />

              </td>
              <td align="center" v-if="is_show_1" class="data_list">

                <CheckBoxs
                    :is_check="data_list_1[results.id] == 4"
                    :check="()=> Checks(1,results.id,4) "
                    class_name="red_color"
                />

              </td>
              <td align="center" v-if="is_show_1" class="data_list">

                <CheckBoxs
                    :is_check="data_list_1[results.id] == 5"
                    :check="()=> Checks(1,results.id,5) "
                    class_name="red_color"
                />

              </td>





              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_2[results.id] == 1"
                    :check="()=> Checks(2,results.id,1) "
                    class_name="green_color"
                />
              </td>
              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_2[results.id] == 2"
                    :check="()=> Checks(2,results.id,2) "
                    class_name="green_color"
                />
              </td>
              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_2[results.id] == 3"
                    :check="()=> Checks(2,results.id,3) "
                    class_name="green_color"
                />
              </td>
              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_2[results.id] == 4"
                    :check="()=> Checks(2,results.id,4) "
                    class_name="green_color"
                />
              </td>
              <td align="center" v-if="is_show_2" :class="{ is_not: !is_rate_2 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_2[results.id] == 5"
                    :check="()=> Checks(2,results.id,5) "
                    class_name="green_color"
                />
              </td>




              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_3[results.id] == 1"
                    :check="()=> Checks(3,results.id,1) "
                    class_name="blue_color"
                />
              </td>
              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_3[results.id] == 2"
                    :check="()=> Checks(3,results.id,2) "
                    class_name="blue_color"
                />
              </td>
              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_3[results.id] == 3"
                    :check="()=> Checks(3,results.id,3) "
                    class_name="blue_color"
                />
              </td>
              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_3[results.id] == 4"
                    :check="()=> Checks(3,results.id,4) "
                    class_name="blue_color"
                />
              </td>
              <td align="center" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_3[results.id] == 5"
                    :check="()=> Checks(3,results.id,5) "
                    class_name="blue_color"
                />
              </td>





              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_4[results.id] == 1"
                    :check="()=> Checks(4,results.id,1) "
                    class_name="yellow_color"
                />
              </td>
              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_4[results.id] == 2"
                    :check="()=> Checks(4,results.id,2) "
                    class_name="yellow_color"
                />
              </td>
              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_4[results.id] == 3"
                    :check="()=> Checks(4,results.id,3) "
                    class_name="yellow_color"
                />
              </td>
              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_4[results.id] == 4"
                    :check="()=> Checks(4,results.id,4) "
                    class_name="yellow_color"
                />
              </td>
              <td align="center" v-if="is_show_4" :class="{ is_not: !is_rate_4 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_4[results.id] == 5"
                    :check="()=> Checks(4,results.id,5) "
                    class_name="yellow_color"
                />
              </td>


              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_5[results.id] == 1"
                    :check="()=> Checks(5,results.id,1) "
                    class_name="violet_color"
                />
              </td>
              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_5[results.id] == 2"
                    :check="()=> Checks(5,results.id,2) "
                    class_name="violet_color"
                />
              </td>
              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_5[results.id] == 3"
                    :check="()=> Checks(5,results.id,3) "
                    class_name="violet_color"
                />
              </td>
              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_5[results.id] == 4"
                    :check="()=> Checks(5,results.id,4) "
                    class_name="violet_color"
                />
              </td>
              <td align="center" v-if="is_show_5" :class="{ is_not: !is_rate_5 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_5[results.id] == 5"
                    :check="()=> Checks(5,results.id,5) "
                    class_name="violet_color"
                />
              </td>


              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_6[results.id] == 1"
                    :check="()=> Checks(6,results.id,1) "
                    class_name="orange_color"
                />
              </td>

              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_6[results.id] == 2"
                    :check="()=> Checks(6,results.id,2) "
                    class_name="orange_color"
                />
              </td>
              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_6[results.id] == 3"
                    :check="()=> Checks(6,results.id,3) "
                    class_name="orange_color"
                />
              </td>
              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_6[results.id] == 4"
                    :check="()=> Checks(6,results.id,4) "
                    class_name="orange_color"
                />
              </td>
              <td align="center" v-if="is_show_6" :class="{ is_not: !is_rate_6 }" class="data_list">
                <CheckBoxs
                    :is_check="data_list_6[results.id] == 5"
                    :check="()=> Checks(6,results.id,5) "
                    class_name="orange_color"
                />
              </td>


            </tr>
            </tbody>
            <tr>
              <td colspan="2" align="center">
                รวมคะแนนสมรรถนะทางวิชาชีพครู ด้านการจัดการเรียนรู้<br>
                (เต็มคะแนน {{ (count * 5) }})
              </td>
              <td colspan="5" v-if="is_show_1" :class="{ is_not: !is_rate_2 }" align="center"><b>{{ this.total1() }}</b></td>
              <td colspan="5" v-if="is_show_2" :class="{ is_not: !is_rate_3 }" align="center"><b>{{ this.total2() }}</b></td>
              <td colspan="5" v-if="is_show_3" :class="{ is_not: !is_rate_3 }" align="center"><b>{{ this.total3() }}</b></td>
              <td colspan="5" v-if="is_show_4" :class="{ is_not: !is_rate_3 }" align="center"><b>{{ this.total4() }}</b></td>
              <td colspan="5" v-if="is_show_5" :class="{ is_not: !is_rate_3 }" align="center"><b>{{ this.total5() }}</b></td>
              <td colspan="5" v-if="is_show_6" :class="{ is_not: !is_rate_3 }" align="center"><b>{{ this.total6() }}</b></td>
            </tr>
          </table>


        </v-form>
      </div>

    </div>
  </v-breadcrumbs>


</template>

<script>
import {getData} from "../lib/controller";
import {baseUrl} from "../const/api";
import $ from 'jquery'
import {ResponseData} from "../lib/utility";
import CheckBoxsView from "../components/CheckBoxsView";

export default {
  name: "Rate",
  components:{
    CheckBoxs : CheckBoxsView
  },
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    check: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'ประวัติส่วนตัว',
        disabled: true,
        href: '/profile',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],

    time: null,
    menu2: false,
    modal2: false,
    birthdate: "",
    section_title: "-",
    section_id: 0,
    form_title: "-",
    statement: "-",
    title_sub: "-",
    rate_data: {},
    data_list_1: [],
    data_list_2: [],
    data_list_3: [],
    data_list_4: [],
    data_list_5: [],
    data_list_6: [],
    count: 0,
    year: 1,
    is_rate_1: true,
    is_rate_2: false,
    is_rate_3: false,
    is_rate_4: false,
    is_rate_5: false,
    is_rate_6: false,
    users: JSON.parse(localStorage.getItem('user')),
    is_show_1:false,
    is_show_2:false,
    is_show_3:false,
    is_show_4:false,
    is_show_5:false,
    is_show_6:false,
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  async mounted() {
    this.initialize();
    await this.GetData();
  },
  methods: {
    total1() {
      let sum = 0;
      this.data_list_1.forEach((value) => {
        //console.log(value)
        if (value) {
          sum += parseInt(value)
        }
      });
      return sum;
    },
    total2() {
      let sum = 0;
      this.data_list_2.forEach((value) => {
        if (value) {
          sum += parseInt(value)
        }
      });
      return sum;
    },
    total3() {
      let sum = 0;
      this.data_list_3.forEach((value) => {
        if (value) {
          sum += parseInt(value)
        }
      });
      return sum;
    },
    total4() {
      let sum = 0;
      this.data_list_4.forEach((value) => {
        if (value) {
          sum += parseInt(value)
        }
      });
      return sum;
    },
    total5() {
      let sum = 0;
      this.data_list_5.forEach((value) => {
        if (value) {
          sum += parseInt(value)
        }
      });
      return sum;
    },
    total6() {
      let sum = 0;
      this.data_list_6.forEach((value) => {
        if (value) {
          sum += parseInt(value)
        }
      });
      return sum;
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.check = false;
        this.Update();
      } else {
        this.check = true;
      }
    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async GetData() {

      this.is_loading = true;
      console.log(this)

      let params = {
        "type":this.$route.params.type,
        "student_id":this.$route.params.student_id,
        "id":this.$route.params.id,
        "year":this.year,
      }

      await getData(params, (response) => {

        const {status, data} = ResponseData(response)

        let count = 0

        switch (status) {
          case 200:
            console.log(data.profile)
            this.section_title = data.section.title;
            this.section_id = data.section.id;
            this.form_title = data.data.title;
            this.statement = data.data.statement.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.title_sub = data.data.title_sub.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.rate_data = data.rate;

            this.assessment = data.assessment;


            this.rate_data.forEach((result) => {
              result.list.forEach((results) => {
                results.index = count;
                count++
              });

              this.is_show_1 = true;

              let rate_1 = this.assessment.find((result)=> result.rate_level == 1 )
              let rate_2 = this.assessment.find((result)=> result.rate_level == 2 )
              let rate_3 = this.assessment.find((result)=> result.rate_level == 3 )

              let rate_4 = this.assessment.find((result)=> result.rate_level == 4 )
              let rate_5 = this.assessment.find((result)=> result.rate_level == 5 )
              let rate_6 = this.assessment.find((result)=> result.rate_level == 6 )

              console.log({
                rate_1,rate_2,rate_3,rate_4,rate_5,rate_6
              })


              if(rate_1){
                let data_list_1 = [];
                JSON.parse(rate_1.data).forEach((result)=>{
                  data_list_1[result.rate_list_id] = result.val;
                });

                this.data_list_1 = data_list_1;
                this.is_rate_2 = true;
                this.is_show_2 = true;
              }


              if(rate_2){
                let data_list_2 = [];
                JSON.parse(rate_2.data).forEach((result)=>{
                  data_list_2[result.rate_list_id] = result.val;
                });

                this.data_list_2 = data_list_2;
                this.is_rate_3 = true;
                this.is_show_3 = true;
              }

              if(rate_3){
                let data_list_3 = [];
                JSON.parse(rate_3.data).forEach((result)=>{
                  data_list_3[result.rate_list_id] = result.val;
                });

                this.data_list_3 = data_list_3;
                this.is_rate_4 = true;
                this.is_show_4 = true;
              }


              if(rate_4){
                let data_list_4 = [];
                JSON.parse(rate_4.data).forEach((result)=>{
                  data_list_4[result.rate_list_id] = result.val;
                });

                this.data_list_4 = data_list_4;
                this.is_rate_5 = true;
                this.is_show_5 = true;
              }

              if(rate_5){
                let data_list_5 = [];
                JSON.parse(rate_5.data).forEach((result)=>{
                  data_list_5[result.rate_list_id] = result.val;
                });

                this.data_list_5 = data_list_5;
                this.is_rate_6 = true;
                this.is_show_6 = true;
              }


              if(rate_6){
                let data_list_6 = [];
                JSON.parse(rate_6.data).forEach((result)=>{
                  data_list_6[result.rate_list_id] = result.val;
                });

                this.data_list_6 = data_list_6;
              }




            });

            this.count = count;


            this.$refs.topProgress.done()
            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
    Checks(type,index,val){
      console.log(type,index,val)
    },
  },
}


</script>

<style scoped>
.card_container {
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}

.card_header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_card {
  padding: 2rem;
}

.image_preview_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#image_preview {
  width: 20rem;
  text-align: center;
}

.custom_table {
  border-collapse: collapse;
}

.table_container {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.table_form {
  width: 100%;
  border: #2f2f2f;
  color: #333232;
}
.form_card{
  overflow-x: scroll;
  color: #333232;
}

</style>
